import React from 'react';
import './Header.css';

const Header = () => {
  return (
    <header >
      <nav>
        <ul className="nav-list">
          <li><a href="#customers">Customers</a></li>
          <li><a href="#appointments">Appointments</a></li>
          <li><a href="#export">Export</a></li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
