import React, { useState, useEffect } from "react";
import { saveAs } from "file-saver";
import axios from "axios";
import "./App.css";
import Header from "./Header";

const App = () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [details, setDetails] = useState("");
  const [sessions, setSessions] = useState("");
  const [customers, setCustomers] = useState([]);
  const [editingId, setEditingId] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const [appointments, setAppointments] = useState(
    Array.from({ length: 10 }, () =>
      Array(7).fill({ customer: "", reminder: "" })
    )
  );

  const [hiddenCells, setHiddenCells] = useState([]); // Initialize with an empty array

  const [isAdmin, setIsAdmin] = useState(() => {
    const savedIsAdmin = localStorage.getItem("isAdmin");
    return savedIsAdmin === "true";
  });
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const API_URL = "http://localhost:5000";

  useEffect(() => {
    const fetchHiddenCells = async () => {
      try {
        const response = await axios.get(`${API_URL}/hiddenCells`);
        setHiddenCells(
          response.data.length
            ? response.data
            : appointments.map((row) => row.map(() => false))
        );
      } catch (error) {
        console.error("Error fetching hidden cells:", error);
        setErrorMessage("Failed to load hidden cells."); // Improved error handling
      }
    };

    fetchHiddenCells();
  }, [appointments]); // Dependencies only include appointments

  const handleHideCell = async (rowIndex, colIndex) => {
    setHiddenCells((prevHiddenCells) => {
      const updatedHiddenCells = [...prevHiddenCells];
      updatedHiddenCells[rowIndex][colIndex] = true; // Set the cell to hidden
      saveHiddenCells(updatedHiddenCells); // Save the changes to the server
      return updatedHiddenCells;
    });
  };

  const handleHideAllCells = () => {
    const updatedHiddenCells = appointments.map((row) => row.map(() => true)); // Set all cells to hidden
    setHiddenCells(updatedHiddenCells);
    saveHiddenCells(updatedHiddenCells); // Save the changes to the server
  };

  const handleShowCell = async (rowIndex, colIndex) => {
    // Set the specific cell to visible (i.e., false)
    setHiddenCells((prevHiddenCells) => {
      const updatedHiddenCells = [...prevHiddenCells];
      updatedHiddenCells[rowIndex][colIndex] = false; // Mark as visible
      saveHiddenCells(updatedHiddenCells); // Save the changes to the server
      return updatedHiddenCells;
    });
  };

  // Helper function to save hidden cells to the server
  const saveHiddenCells = async (updatedHiddenCells) => {
    try {
      const response = await axios.post(
        "http://localhost:5000/hiddenCells",
        updatedHiddenCells
      );
      console.log("Hidden cells updated successfully:", response.data);
    } catch (error) {
      console.error("Error updating hidden cells:", error);
      // You might also want to display an error message to the user here
    }
  };

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const response = await axios.get(`${API_URL}/customers`);
        setCustomers(response.data);
      } catch (error) {
        setErrorMessage("Error fetching customers");
        console.error("Error fetching customers:", error);
      }
    };

    const fetchAppointments = async () => {
      try {
        const response = await axios.get(`${API_URL}/appointments`);
        setAppointments(response.data.schedule || []);
      } catch (error) {
        setErrorMessage("Error fetching appointments");
        console.error("Error fetching appointments:", error);
      }
    };

    fetchCustomers();
    fetchAppointments();
  }, []);

  useEffect(() => {
    localStorage.setItem("isAdmin", isAdmin);
  }, [isAdmin]);

  useEffect(() => {
    const alertSound = new Audio("/alert.wav"); // Update with your sound file path in the public directory
    const alertedCustomers = new Set(); // Track alerted customers within the 15-minute interval window

    const checkReminders = () => {
      const now = new Date();
      const twoHoursBefore = new Date(now.getTime() + 2 * 60 * 60 * 1000); // 2 hours from now
      const oneHourBefore = new Date(now.getTime() + 60 * 60 * 1000); // 1 hour from now
      const fifteenMinutesLater = new Date(now.getTime() + 15 * 60 * 1000); // 15 minutes from now

      appointments.forEach((row) => {
        row.forEach((cell) => {
          if (cell.reminder) {
            const reminderDate = new Date(cell.reminder);

            // Send WhatsApp message 2 hours before the appointment
            if (
              reminderDate <= twoHoursBefore &&
              !alertedCustomers.has(cell.customer)
            ) {
              const customer = customers.find((c) => c.name === cell.customer);
              if (customer && customer.phone) {
                const whatsappURL = `https://wa.me/${customer.phone}`;
                window.open(whatsappURL, "_blank"); // Open WhatsApp URL in a new tab
                alertedCustomers.add(cell.customer);
                alertSound.play();
              }
            }

            // If reminder is between 1 hour and 15 minutes from now, play sound every 15 minutes
            if (
              reminderDate <= oneHourBefore &&
              reminderDate >= fifteenMinutesLater
            ) {
              if (!alertedCustomers.has(cell.customer)) {
                alertSound
                  .play()
                  .catch((err) => console.error("Sound playback issue:", err));

                if (Notification.permission === "granted") {
                  new Notification(
                    `Reminder: ${cell.customer}'s appointment is coming up soon!`
                  );
                }

                alertedCustomers.add(cell.customer);
                setTimeout(() => {
                  alertedCustomers.delete(cell.customer);
                }, 15 * 60 * 1000);
              }
            }
          }
        });
      });
    };

    const interval = setInterval(checkReminders, 60000); // Check every minute
    return () => clearInterval(interval); // Clear interval on component unmount
  }, [appointments, customers]);

  const handleExport = () => {
    const csv = [
      ["Name", "Phone", "Details", "Sessions"],
      ...customers.map((customer) => [
        customer.name,
        customer.phone,
        customer.details,
      ]),
    ]
      .map((e) => e.join(","))
      .join("\n");

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "customers.csv");
  };

  const handleLogin = (e) => {
    e.preventDefault();
    if (username === "admin" && password === "password") {
      setIsAdmin(true);
      setUsername("");
      setPassword("");
    } else {
      alert("Invalid credentials");
    }
  };

  const handleLogout = () => {
    setIsAdmin(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (name && phone && details && sessions) {
      if (!/^\d+$/.test(phone)) {
        alert("Please enter a valid phone number containing only digits.");
        return;
      }

      if (!editingId) {
        const existingCustomer = customers.find(
          (customer) => customer.name.toLowerCase() === name.toLowerCase()
        );
        if (existingCustomer) {
          alert("Customer with this name already exists.");
          return;
        }
      }

      try {
        if (editingId) {
          const response = await axios.put(
            `${API_URL}/customers/${editingId}`,
            { name, phone: Number(phone), details, sessions }
          );
          setCustomers(
            customers.map((customer) =>
              customer._id === editingId ? response.data : customer
            )
          );
        } else {
          const response = await axios.post(`${API_URL}/customers`, {
            name,
            phone: Number(phone),
            details,
            sessions,
          });
          setCustomers([...customers, response.data]);
        }
        resetForm();
      } catch (error) {
        setErrorMessage("Error saving customer");
        console.error("Error saving customer:", error);
      }
    }
  };

  const resetForm = () => {
    setName("");
    setPhone("");
    setDetails("");
    setSessions("");
    setEditingId(null);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${API_URL}/customers/${id}`);
      setCustomers(customers.filter((customer) => customer._id !== id));
    } catch (error) {
      setErrorMessage("Error deleting customer");
      console.error("Error deleting customer:", error);
    }
  };

  const handleEdit = (customer) => {
    setName(customer.name);
    setPhone(customer.phone);
    setDetails(customer.details);
    setSessions(customer.sessions);
    setEditingId(customer._id);
  };

  const filteredCustomers = searchTerm
    ? customers.filter((customer) =>
        customer.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : customers;

  const handleAppointmentChange = async (row, col, value) => {
    const newAppointments = [...appointments];
    newAppointments[row][col] = value;
    setAppointments(newAppointments);

    try {
      await axios.post(`${API_URL}/appointments`, {
        schedule: newAppointments,
      });
    } catch (error) {
      setErrorMessage("Error saving appointments");
      console.error("Error saving appointments:", error);
    }
  };

  const handleSetReminder = async (row, col, reminder) => {
    const newAppointments = [...appointments];
    newAppointments[row][col].reminder = reminder;

    try {
      await axios.put(`${API_URL}/appointments/reminder`, {
        schedule: newAppointments,
      });

      // Update state
      setAppointments(newAppointments);
    } catch (error) {
      console.error("Error setting reminder:", error);
    }
  };

  useEffect(() => {
    if ("Notification" in window) {
      Notification.requestPermission().then((permission) => {
        if (permission === "denied") {
          console.error("Notification permission denied");
        }
      });
    }
  }, []);

  return (
    <div className="container">
      <header className="header">
        <Header />
        <h1>Customer Numbers</h1>
        {errorMessage && <p className="error">{errorMessage}</p>}
        <div className="admin-section">
          {isAdmin ? (
            <>
              <button onClick={handleLogout}>Logout</button>
            </>
          ) : (
            <form className="login-form" onSubmit={handleLogin}>
              <input
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <button type="submit">Login</button>
            </form>
          )}
        </div>
      </header>

      {isAdmin && (
        <>
          <form className="namesform" onSubmit={handleSubmit}>
            <button onClick={handleExport} className="export-button">
              Export Data
            </button>
            <input
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <input
              type="tel"
              placeholder="Phone Number"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
              pattern="\d*"
            />
            <input
              type="text"
              placeholder="Details"
              value={details}
              onChange={(e) => setDetails(e.target.value)}
              required
            />
            <input
              type="text"
              placeholder="Sessions"
              value={sessions}
              onChange={(e) => setSessions(e.target.value)}
              required
            />
            <button className="add-customer" type="submit">
              {editingId ? "Update Customer" : "Add Customer"}
            </button>
          </form>

          <input
            className="searchinput"
            type="text"
            placeholder="Search by Name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />

          <div className="scrollable-table">
            <div className="table">
              <div className="table-header">
                <div className="table-header-item">No.</div>
                <div className="table-header-item">Name</div>
                <div className="table-header-item">Phone Number</div>
                <div className="table-header-item">Details</div>
                <div className="table-header-item">Sessions</div>{" "}
                {/* New column header */}
                <div className="table-header-item">Actions</div>
              </div>
              {filteredCustomers.map((customer, index) => (
                <div className="table-row" key={customer._id}>
                  <div className="table-item">{index + 1}</div>
                  <div className="table-item">{customer.name}</div>
                  <div className="table-item">{customer.phone}</div>
                  <div className="table-item">{customer.details}</div>
                  <div className="table-item">{customer.sessions}</div>
                  {/* New column data */}
                  <div className="table-item">
                    <button
                      onClick={() => handleEdit(customer)}
                      className="edit-button"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handleDelete(customer._id)}
                      className="remove-button"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <h2>Appointment Schedule</h2>
          <table className="appointment-table">
            <thead>
              <tr>
                <th>
                  Hours/ Days
                  <button
                    className="hide-all-cells"
                    onClick={handleHideAllCells}
                  >
                    Hide All
                  </button>
                </th>
                <th>Sat</th>
                <th>Sun</th>
                <th>Mon</th>
                <th>Tue</th>
                <th>Wed</th>
                <th>Thu</th>
                <th>Fri</th>
              </tr>
            </thead>
            <tbody>
              {appointments.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  <td className="td1">{`${rowIndex + 1}:00`}</td>
                  {row.map((cell, colIndex) => (
                    <td
                      className={`td2 ${
                        cell.reminder || cell.customer ? "selected-color" : ""
                      }`}
                      key={colIndex}
                    >
                      {!hiddenCells[rowIndex]?.[colIndex] ? (
                        <>
                          <SearchableDropdown
                            selectedCustomer={cell.customer}
                            customers={customers}
                            onSelect={(customer) => {
                              handleAppointmentChange(rowIndex, colIndex, {
                                customer,
                                reminder: cell.reminder,
                              });
                            }}
                          />
                          <input
                            className="datecellinput"
                            type="datetime-local"
                            value={cell.reminder || ""}
                            onChange={(e) => {
                              handleSetReminder(
                                rowIndex,
                                colIndex,
                                e.target.value
                              );
                            }}
                            placeholder="Set Reminder"
                          />
                          <input
                            type="text"
                            value={
                              " بتاريخ اليوم : " +
                              cell.reminder +
                              " : سلام عليكم ورحمة اللة , سيد / سيدة " +
                              cell.customer +
                              " نود تذكيركم بجلسة العلاج الطبيعي الساعة "
                            }
                            placeholder="Your message here"
                            readOnly
                          />
                          <button
                            className="hidebtn"
                            onClick={() => handleHideCell(rowIndex, colIndex)}
                          >
                            Hide
                          </button>
                        </>
                      ) : (
                        <button
                          onClick={() => handleShowCell(rowIndex, colIndex)}
                          className="show-button"
                        >
                          Show
                        </button>
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

// *very important : this long code is for only dropdown search appointment-table

const SearchableDropdown = ({ selectedCustomer, customers, onSelect }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);

  const filteredCustomers = customers.filter((customer) =>
    customer.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSelect = (customerName) => {
    onSelect(customerName);
    setSearchTerm(customerName);
    setIsDropdownOpen(false);
  };

  const handleClearSelection = () => {
    onSelect("");
    setSearchTerm("");
    setIsDropdownOpen(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      setHighlightedIndex((prev) =>
        Math.min(prev + 1, filteredCustomers.length - 1)
      );
    } else if (e.key === "ArrowUp") {
      setHighlightedIndex((prev) => Math.max(prev - 1, 0));
    } else if (e.key === "Enter") {
      if (highlightedIndex >= 0) {
        handleSelect(filteredCustomers[highlightedIndex].name);
      }
    }
  };

  return (
    <div className="dropdown" onFocus={() => setIsDropdownOpen(true)}>
      <input
        type="text"
        value={searchTerm || selectedCustomer}
        onChange={(e) => setSearchTerm(e.target.value)}
        onKeyDown={handleKeyDown}
        placeholder=""
        className="search-input"
      />
      {isDropdownOpen && (
        <ul className="dropdown-menu">
          <li
            onClick={handleClearSelection}
            className={`dropdown-item ${
              selectedCustomer === "" ? "highlighted" : ""
            }`}
          >
            Clear
          </li>
          {filteredCustomers.map((customer, index) => (
            <li
              key={customer._id}
              onClick={() => handleSelect(customer.name)}
              className={`dropdown-item ${
                highlightedIndex === index ? "highlighted" : ""
              }`}
            >
              {customer.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

// -----------end-of-------dropdown search appointment-table-----------------------------------------------

export default App;
